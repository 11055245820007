import { InfoOutlined, PlayArrow } from "@material-ui/icons";
import "./featured.scss";
import Watch from "../../pages/watch/Watch";
import { Link,useHistory } from "react-router-dom";

export default function Featured({ type,cate }) {
  let history = useHistory();

  function handleChange(value) {
    history.push(`/`);
    history.push(`categoria/${value}`);
  }
  return (
   
   <div className="featured">
      
        <div className="category">
          <span> Categorías</span>
          <select name="genre" id="genre" onChange={event => handleChange(event.target.value)} >
           
            <option value={0}>Todos</option>
            {
              

              cate.map((item, i) => (
                item.name !== "Todos" &&
                  <option key={item.id} value={item.id}>{item.name}</option>
                
              ))
              
            }
          </select>
        </div>
      
      <div className="info">
        <img
          src="https://tv2.yottalan.com/media/images/users/One-256x256.png"
          alt=""
          
        />
        <h1 className="desc">
        Disfruta de la mejor programación nacional e internacional en vivo con toda la familia.
        </h1>
        
      </div>
    </div>
  );
}

import { ArrowDropDown, Notifications, Search,Menu } from "@material-ui/icons";
import { useContext, useState } from "react";
import "./navbar.scss";
import { Link } from "react-router-dom";
import { logout } from "../../authContext/AuthActions";
import { AuthContext } from "../../authContext/AuthContext";



const Navbar = () => {
  const [isScrolled, setIsScrolled] = useState(false);
  const {dispatch}=useContext(AuthContext)

  window.onscroll = () => {
    setIsScrolled(window.pageYOffset === 0 ? false : true);
    return () => (window.onscroll = null);
  };

 function salir() {

    var token2=localStorage.getItem("user").replace(/["]+/g, '');

    fetch("https://core-one.nextwarela.com/auth/logout/",{
      method:"POST",
      headers: {
        'Content-Type': 'application/json; charset=UTF-8',
        'Authorization': `Bearer ${token2}`,
        // 'Content-Type': 'application/x-www-form-urlencoded',
      },
    }).then((response)=>{
      //console.log(response);
      dispatch(logout());
      localStorage.removeItem("user");

    })



  }
  return (
    <div className={isScrolled ? "navbar scrolled" : "navbar"}>
      <div className="container">
        <div className="left">
          
          
        </div>
        <div className="right">
          
          <div className="profile">
            <Menu className="icon" />
            <div className="options">
            <Link to="/" className="link">
              <span onClick={()=>salir()}>Logout</span>
            </Link>

            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Navbar;